/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const SourceSerifPro_200ExtraLight = require('./SourceSerifPro_200ExtraLight.ttf');
export const SourceSerifPro_200ExtraLight_Italic = require('./SourceSerifPro_200ExtraLight_Italic.ttf');
export const SourceSerifPro_300Light = require('./SourceSerifPro_300Light.ttf');
export const SourceSerifPro_300Light_Italic = require('./SourceSerifPro_300Light_Italic.ttf');
export const SourceSerifPro_400Regular = require('./SourceSerifPro_400Regular.ttf');
export const SourceSerifPro_400Regular_Italic = require('./SourceSerifPro_400Regular_Italic.ttf');
export const SourceSerifPro_600SemiBold = require('./SourceSerifPro_600SemiBold.ttf');
export const SourceSerifPro_600SemiBold_Italic = require('./SourceSerifPro_600SemiBold_Italic.ttf');
export const SourceSerifPro_700Bold = require('./SourceSerifPro_700Bold.ttf');
export const SourceSerifPro_700Bold_Italic = require('./SourceSerifPro_700Bold_Italic.ttf');
export const SourceSerifPro_900Black = require('./SourceSerifPro_900Black.ttf');
export const SourceSerifPro_900Black_Italic = require('./SourceSerifPro_900Black_Italic.ttf');
